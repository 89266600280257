var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.mainPackData)?_c('VGridScroll',[_c('div',{staticClass:"home-projects__container"},[_c('div',{staticClass:"home-projects__city-wrapper"},[(_vm.userCities?.length && _vm.projects.length)?_c('VSelect',{staticClass:"city-filter home-projects__city",attrs:{"disable-clear":false,"options":_vm.userCities,"placeholder":_vm.$t('city'),"reduce":(x) => x?.id,"label":"name","track-by":"id"},model:{value:(_vm.selectedCityId),callback:function ($$v) {_vm.selectedCityId=$$v},expression:"selectedCityId"}}):_vm._e()],1),(_vm.isEmpty)?_c('VGridRow',{staticClass:"home-projects__cities-wrapper grid_center"},[_c('h1',[_vm._v(_vm._s(_vm.$t('page.home.projectEmpty')))])]):_c('VGridRow',{staticClass:"home-projects__list"},_vm._l((_vm.projects),function(project,indexProject){return _c('VGridCol',{key:indexProject,staticClass:"home-projects__card"},[_c('RouterLink',{staticClass:"home-projects__image",attrs:{"to":{
                        name: 'prescription.list',
                        query: { projectId: project.id },
                    }}},[(project.fileUrl)?_c('img',{staticClass:"home-projects__image",attrs:{"src":project.fileUrl}}):_c('img',{staticClass:"home-projects__image",attrs:{"src":require("@/assets/projectpreview.jpg?inline")}})]),_c('div',{staticClass:"home-projects__card-header"},[_c('div',{staticClass:"home-projects__card-title"},[_vm._v(" "+_vm._s(project.project)+" ")]),_c('RouterLink',{attrs:{"to":_vm.routeCatalogDislike(project.id)}},[_c('div',{staticClass:"home-projects__card-likes"},[_vm._v(" "+_vm._s(_vm.setLighter(_vm.LighterTypeEnum.Overdue, project.id))+" "),_c('DislikeSVG')],1)])],1),_c('div',{staticClass:"home-projects__card-info-scroll"},_vm._l((_vm.types),function(type,index){return _c('div',{key:index,staticClass:"home-projects__card-info-wrapper"},[_c('div',{staticClass:"home-projects__card-info",class:{
                                'home-projects__card-info-empty':
                                    !_vm.sections(project.id).length || type.code !== _vm.prescriptionTypeEnum.Building,
                                'home-projects__card-info-hidden': !project.visibility?.[type.code],
                            }},[_c('div',{staticClass:"home-projects__card-info-type"},[_vm._v(_vm._s(type.title))]),_c('div',{staticClass:"home-projects__card-lights"},[_c('RouterLink',{attrs:{"to":_vm.routerReportCatalogLighter(
                                            project.id,
                                            null,
                                            _vm.LighterTypeEnum.Red,
                                            undefined,
                                            type.code,
                                        )}},[_c('div',{staticClass:"home-projects__status home-projects__status_red"},[_vm._v(" "+_vm._s(_vm.setLighter(_vm.LighterTypeEnum.Red, project.id, undefined, type.code))+" ")])]),_c('RouterLink',{attrs:{"to":_vm.routerReportCatalogLighter(
                                            project.id,
                                            null,
                                            _vm.LighterTypeEnum.Yellow,
                                            undefined,
                                            type.code,
                                        )}},[_c('div',{staticClass:"home-projects__status home-projects__status_yellow"},[_vm._v(" "+_vm._s(_vm.setLighter(_vm.LighterTypeEnum.Yellow, project.id, undefined, type.code))+" ")])]),_c('RouterLink',{attrs:{"to":_vm.routerReportCatalogLighter(
                                            project.id,
                                            null,
                                            _vm.LighterTypeEnum.Green,
                                            undefined,
                                            type.code,
                                        )}},[_c('div',{staticClass:"home-projects__status home-projects__status_green"},[_vm._v(" "+_vm._s(_vm.setLighter(_vm.LighterTypeEnum.Green, project.id, undefined, type.code))+" ")])])],1),(_vm.sections(project.id).length && type.code === _vm.prescriptionTypeEnum.Building)?_c('div',{staticClass:"home-projects__card-info-arrow",class:{
                                    'home-projects__card-info-arrow-rotate': project.visibility?.[type.code],
                                },on:{"click":function($event){return _vm.changeVisibility(project.id, type.code)}}},[_c('ArrowSVG')],1):_c('div',{staticStyle:{"width":"25px"}})]),(type.code === _vm.prescriptionTypeEnum.Building)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(project.visibility?.[type.code]),expression:"project.visibility?.[type.code]"}],staticClass:"home-projects__card-info-inner-wrapper"},[_vm._l((_vm.sections(project.id)),function(section,indexSection){return _c('div',{key:indexSection,staticClass:"home-projects__card-info-inner"},[_c('div',{staticClass:"home-projects__card-info-inner-type"},[_c('RouterLink',{attrs:{"to":_vm.routerReportCatalogLighter(
                                                project.id,
                                                section.id,
                                                undefined,
                                                undefined,
                                                type.code,
                                            )}},[_vm._v(" "+_vm._s(section.name)+" ")])],1),_c('div',{staticClass:"home-projects__card-lights"},[_c('RouterLink',{attrs:{"to":_vm.routerReportCatalogLighter(
                                                project.id,
                                                section.id,
                                                _vm.LighterTypeEnum.Red,
                                                undefined,
                                                type.code,
                                            )}},[_c('div',{staticClass:"home-projects__status_red home-projects__status"},[_vm._v(" "+_vm._s(_vm.setLighter(_vm.LighterTypeEnum.Red, project.id, section.id))+" ")])]),_c('RouterLink',{attrs:{"to":_vm.routerReportCatalogLighter(
                                                project.id,
                                                section.id,
                                                _vm.LighterTypeEnum.Yellow,
                                                undefined,
                                                type.code,
                                            )}},[_c('div',{staticClass:"home-projects__status_yellow home-projects__status"},[_vm._v(" "+_vm._s(_vm.setLighter(_vm.LighterTypeEnum.Yellow, project.id, section.id))+" ")])]),_c('RouterLink',{attrs:{"to":_vm.routerReportCatalogLighter(
                                                project.id,
                                                section.id,
                                                _vm.LighterTypeEnum.Green,
                                                undefined,
                                                type.code,
                                            )}},[_c('div',{staticClass:"home-projects__status_green home-projects__status"},[_vm._v(" "+_vm._s(_vm.setLighter(_vm.LighterTypeEnum.Green, project.id, section.id))+" ")])])],1),_c('div',{staticStyle:{"width":"25px"}})])}),_c('div',{staticClass:"home-projects__card-info-inner"},[_c('div',{staticClass:"home-projects__card-info-inner-type"},[_c('RouterLink',{attrs:{"to":_vm.routerReportCatalogLighter(
                                                project.id,
                                                null,
                                                undefined,
                                                undefined,
                                                type.code,
                                            )}},[_vm._v(" "+_vm._s(_vm.$t('page.home.withoutSection'))+" ")])],1),_c('div',{staticClass:"home-projects__card-lights"},[_c('RouterLink',{attrs:{"to":_vm.routerReportCatalogLighter(
                                                project.id,
                                                null,
                                                _vm.LighterTypeEnum.Red,
                                                true,
                                                type.code,
                                            )}},[_c('div',{staticClass:"home-projects__status_red home-projects__status"},[_vm._v(" "+_vm._s(_vm.setLighterOther(_vm.LighterTypeEnum.Red, project.id))+" ")])]),_c('RouterLink',{attrs:{"to":_vm.routerReportCatalogLighter(
                                                project.id,
                                                null,
                                                _vm.LighterTypeEnum.Yellow,
                                                true,
                                                type.code,
                                            )}},[_c('div',{staticClass:"home-projects__status_yellow home-projects__status"},[_vm._v(" "+_vm._s(_vm.setLighterOther(_vm.LighterTypeEnum.Yellow, project.id))+" ")])]),_c('RouterLink',{attrs:{"to":_vm.routerReportCatalogLighter(
                                                project.id,
                                                null,
                                                _vm.LighterTypeEnum.Green,
                                                true,
                                                type.code,
                                            )}},[_c('div',{staticClass:"home-projects__status_green home-projects__status"},[_vm._v(" "+_vm._s(_vm.setLighterOther(_vm.LighterTypeEnum.Green, project.id))+" ")])])],1),_c('div',{staticStyle:{"width":"25px"}})])],2):_vm._e()])}),0)],1)}),1)],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }